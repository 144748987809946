import React from "react"
import Page from "@components/Layout/DocumentationPage"
import Paragraph from "../../../components/Paragraph"
import Link from "../../../components/Link"
import List from "../../../components/List"
import Heading2 from "../../../components/Headings/Heading2"

export default () => (
  <Page title={"Integrations"}>
    <Paragraph>
      We integrate with various services that you're already using. Integrations
      can be managed by organization owners.
    </Paragraph>
    <Paragraph>
      Our integrations make outbound requests, if you're self-hosting instances
      of these services you may have to whitelist our{" "}
      <Link to={"/docs/ip-ranges"}>static set of IP addresses</Link> for the
      integration to function correctly.
    </Paragraph>
    <Heading2>Change Tracking</Heading2>
    <Paragraph>
      These integrations allows us to keep track of changes that are happening
      within your repositories. After connecting one of them within your account
      or organization you can create projects out of your repositories.
    </Paragraph>
    <List>
      <li>
        <Link to={"/docs/integrations/github"}>GitHub</Link>
      </li>
      <li>
        <Link to={"/docs/integrations/github"}>Bitbucket Server</Link>
      </li>
    </List>
    <Heading2>Missing an integration?</Heading2>
    <Paragraph>
      Are you using a service that is not listed above?{" "}
      <Link
        to={"mailto:changehub@madewithlove.be?Subject=Missing%20integration"}
        external
        target={"_blank"}
        rel={"noopener noreferrer"}
      >
        let us know
      </Link>{" "}
      and we will investigate what it takes to integrate.
    </Paragraph>
  </Page>
)
